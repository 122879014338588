






























import { Component, Vue, Prop } from 'vue-property-decorator'
import BaseIcon from '@/components/base/BaseIcon.vue'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export interface RoomInterface {
  title: string
  address: string
  images: {
    url: string
  }[]
}

@Component({
  name: 'Room',
  components: {
    Swiper,
    SwiperSlide,
    BaseIcon,
  },
})
export default class Room extends Vue {
  @Prop({ required: true })
  room!: RoomInterface

  swiperOptions = {
    slidesPerView: 1,
    pagination: {
      el: '.Room__pagination',
      clickable: true,
    },
  }
}
