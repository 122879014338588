









































import { Nullable } from '@/types'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
const RoomsStore = namespace('RoomsStore')
import { RoomModel } from '@/modules/MyRoomsPage/models/RoomModel'

import Room, { RoomInterface } from './Room.vue'
import Overlay from '@/components/Overlay.vue'
import BaseIcon from '@/components/base/BaseIcon.vue'
import BaseButton from '@/components/base/BaseButton.vue'

@Component({
  name: 'SelectRoom',
  components: {
    Overlay,
    BaseIcon,
    BaseButton,
    Room,
  },
})
export default class SelectRoom extends Vue {
  @Prop({ default: null })
  value!: Nullable<number>

  @RoomsStore.Getter
  public roomList!: RoomModel[]

  get rooms(): RoomInterface[] {
    return this.roomList
  }

  get columns(): number {
    return Math.min(Math.max(this.rooms.length, 1), 3)
  }

  selected: Nullable<number> = null

  created(): void {
    this.selected = this.value
  }

  select(id: number): void {
    this.selected = id
    this.$emit('input', this.selected)
    this.$emit('close')
  }

  submit(): void {
    this.$emit('input', this.selected)
    this.$emit('close')
  }
}
